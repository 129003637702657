export function getNavigatorLanguage() {
  if (typeof navigator === 'undefined' || !navigator) {
    return 'en';
  }
  return navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        'en';
  }
  
  export default [
    {
      name: 'English',
      value: 'en',
    },
    {
      name: 'German',
      value: 'de',
    },
    {
      name: 'Spanish',
      value: 'es',
    },
    {
      name: 'French',
      value: 'fr',
    },
    {
      name: 'Hindi',
      value: 'hi',
    },
    {
      name: 'Italian',
      value: 'it',
    },
    {
      name: 'Japanese',
      value: 'ja',
    },
    {
      name: 'Dutch',
      value: 'nl',
    },
    {
      name: 'Portuguese',
      value: 'pt',
    },
    {
      name: 'Russian',
      value: 'ru',
    },
    {
      name: 'Chinese',
      value: 'zh',
    },
  ];
  